import { session } from '@sms_group/frontend-lib/lib'
import { Navigate, Outlet, useLocation } from 'react-router'

export const RequireAuth = () => {
  const currentSession = session.useSessionInfo()
  const location = useLocation()

  if (!currentSession.isAuth()) {
    return <Navigate to={'/signin'} state={{ from: location }} replace />
  }

  return <Outlet />
}
