import { URL_PARAMS } from '@shared/config'
import { useValidatedQueryParams } from '@shared/lib/url'
import { storageLib } from '@sms_group/frontend-lib/lib'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { serviceListQuery } from './service.query'

export const useServiceDefaultList = () => {
  const favoriteIds =
    storageLib.StorageAPI.get<string>('FAVORITE_SERVICES') ?? []
  const favouriteIdsArray =
    favoriteIds instanceof Array ? favoriteIds : [favoriteIds]

  return useQuery({
    ...serviceListQuery({
      favoriteIds: favouriteIdsArray
    })
  })
}

export const useServiceList = () => {
  const { params } = useValidatedQueryParams({
    countryQuery: { type: z.string().optional(), name: URL_PARAMS.COUNTRY },
    intervalQuery: {
      type: z.enum(['20m', '4h']),
      defaultValue: '20m',
      name: URL_PARAMS.INTERVAL
    },
    operator: {
      type: z.number().optional(),
      name: URL_PARAMS.OPERATOR_CODE
    }
  })

  const favoriteIds =
    storageLib.StorageAPI.get<string>('FAVORITE_SERVICES') ?? []
  const favouriteIdsArray =
    favoriteIds instanceof Array ? favoriteIds : [favoriteIds]

  const response = useQuery({
    ...serviceListQuery({
      favoriteIds: favouriteIdsArray,
      bringTime: params.intervalQuery,
      countryCode: params.countryQuery,
      operatorId: params.operator
    })
  })
  return {
    serviceData: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    refetch: response.refetch
  }
}

export const useServiceById = (serviceId: string | undefined) => {
  const servicesData = useQuery({
    ...serviceListQuery({
      favoriteIds: []
    })
  })
  if (!servicesData?.data) return
  return servicesData.data.find((service) => service.serviceCode === serviceId)
}

export const useServiceId = () => {
  const { params, setQueryParams } = useValidatedQueryParams({
    service: {
      type: z.string().optional(),
      defaultValue: undefined,
      name: URL_PARAMS.SERVICE
    }
  })

  const handleUpdate = (countryValue: string | undefined) =>
    setQueryParams({ service: countryValue }, { replace: true })

  return {
    currentServiceId: params.service,
    setServiceId: handleUpdate
  }
}

export const useCurrentService = () => {
  const { currentServiceId } = useServiceId()
  const service = useServiceById(currentServiceId)
  return {
    currentService: service
  }
}
