import { URL_PARAMS } from '@shared/config'
import { useValidatedQueryParams } from '@shared/lib/url'
import { storageLib } from '@sms_group/frontend-lib/lib'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import {
  countryListQuery,
  countryWithOperatorsListQuery
} from './country.query'

export const useCountryDefaultList = () => {
  const favoriteIds =
    storageLib.StorageAPI.get<string>('FAVORITE_COUNTRIES') ?? []
  const favouriteIdsArray =
    favoriteIds instanceof Array ? favoriteIds : [favoriteIds]

  return useQuery({
    ...countryListQuery({
      favoriteIds: favouriteIdsArray
    })
  })
}

export const useCountryList = () => {
  const { params } = useValidatedQueryParams({
    serviceCode: {
      type: z.string().optional(),
      name: URL_PARAMS.SERVICE
    },
    interval: {
      type: z.enum(['20m', '4h']),
      defaultValue: '20m',
      name: URL_PARAMS.INTERVAL
    }
  })

  const favoriteIds =
    storageLib.StorageAPI.get<string>('FAVORITE_COUNTRIES') ?? []
  const favouriteIdsArray =
    favoriteIds instanceof Array ? favoriteIds : [favoriteIds]

  const response = useQuery({
    ...countryListQuery({
      favoriteIds: favouriteIdsArray,
      bringTime: params.interval,
      serviceCode: params.serviceCode
    })
  })

  return {
    countryData: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    refetch: response.refetch
  }
}

export const useCountryWithOperatorsList = () => {
  const response = useQuery({
    ...countryWithOperatorsListQuery()
  })

  return {
    countryData: response.data,
    isLoading: response.isLoading,
    isError: response.isError
  }
}

export const useCountryById = (countryId: string | undefined) => {
  const countriesData = useQuery({
    ...countryListQuery({
      favoriteIds: []
    })
  })
  if (!countriesData?.data) {
    return undefined
  }
  return countriesData.data.find((country) => country.countryCode === countryId)
}

export const useCountryId = () => {
  const { params, setQueryParams } = useValidatedQueryParams({
    country: { type: z.string().optional(), name: URL_PARAMS.COUNTRY }
  })

  const handleUpdate = (countryValue: string | undefined) =>
    setQueryParams({ country: countryValue }, { replace: true })

  return {
    currentCountryId: params.country,
    setCountryId: handleUpdate
  }
}

export const useCurrentCountry = () => {
  const { currentCountryId } = useCountryId()
  const country = useCountryById(currentCountryId)
  return {
    currentCountry: country
  }
}
