import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ZodArray, ZodOptional, ZodSchema, ZodTypeAny } from 'zod'

type QueryParamsConfig<T> = {
  name: string // Имя параметра
  type: ZodSchema<T> // Поддерживаем Zod-схемы
  isOptional?: boolean
  defaultValue?: T
}

type ValidatedParams<T> = {
  [K in keyof T]: T[K] extends { type: ZodSchema<infer U> } ? U : T[K] extends { type: string } ? string | undefined : never
}

// Функция преобразования строки в нужный тип
const unwrapOptionalSchema = (schema: ZodSchema<unknown>): ZodSchema<unknown> => {
  if (schema instanceof ZodOptional) {
    return schema.unwrap() // Извлекаем внутреннюю схему из ZodOptional
  }
  return schema
}

const convertToCorrectType = (value: string, schema: ZodSchema<unknown>): unknown => {
  // Извлекаем внутреннюю схему, если она обёрнута в ZodOptional
  const unwrappedSchema = unwrapOptionalSchema(schema)

  // @ts-expect-error Type 'ZodEnum' is not assignable to type 'ZodSchema<unknown>'
  switch (unwrappedSchema._def.typeName) {
    case 'ZodNumber': {
      const parsedValue = Number(value)
      return isNaN(parsedValue) ? undefined : parsedValue
    }

    case 'ZodBoolean':
      return value === 'true' || value === '1'

    case 'ZodDate': {
      const parsedDate = new Date(value)
      return isNaN(parsedDate.getTime()) ? undefined : parsedDate
    }

    case 'ZodArray': {
      // Проверяем тип элементов массива
      const elementType = (unwrappedSchema as ZodArray<ZodTypeAny>)._def.type // Тип элементов массива
      try {
        const parsedArray = JSON.parse(value) // Преобразуем строку в массив

        if (!Array.isArray(parsedArray)) {
          return undefined // Если это не массив, возвращаем undefined
        }

        // Преобразуем каждый элемент массива в зависимости от его типа
        return parsedArray.map((el) => convertToCorrectType(String(el), elementType))
      } catch {
        return undefined
      }
    }

    case 'ZodString':
      return value

    case 'ZodEnum':
      return value

    default:
      // Для неизвестных типов возвращаем исходное значение
      // @ts-expect-error Type 'ZodEnum' is not assignable to type 'ZodSchema<unknown>'
      console.warn(`Unsupported schema type: ${unwrappedSchema._def.typeName}`)
      return value
  }
}

// Универсальный хук для чтения и изменения параметров URL с кастомной валидацией
export const useValidatedQueryParams = <T extends Record<string, QueryParamsConfig<unknown>>>(paramsConfig: T) => {
  const location = useLocation()
  const navigate = useNavigate()

  // Мемоизируем параметры
  const validatedParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const result: Partial<ValidatedParams<T>> = {}

    Object.keys(paramsConfig).forEach((key) => {
      const { name, type, defaultValue, isOptional } = paramsConfig[key]

      const rawValue = searchParams.getAll(name)

      if (rawValue.length === 0 && isOptional) {
        result[key as keyof T] = undefined
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const schema: ZodSchema<any> = type

      // Преобразуем валидацию строки или массива строк
      const valueToValidate = rawValue.length > 1 ? rawValue.join(',') : rawValue[0] // Если массив — соединяем в строку через запятую

      // Преобразуем строку в нужный тип перед валидацией
      const transformedValue = convertToCorrectType(valueToValidate, schema)

      const parseResult = schema.safeParse(transformedValue)

      result[key as keyof T] = parseResult.success ? parseResult.data : defaultValue
    })

    return result
  }, [location.search, paramsConfig]) // Зависимости для мемоизации

  // Мемоизируем функцию обновления параметров
  const setQueryParams = useCallback(
    (newParams: Partial<ValidatedParams<T>>, options: { replace?: boolean } = {}) => {
      const updatedParams = new URLSearchParams(location.search)

      Object.entries(newParams).forEach(([key, value]) => {
        if (!(key in paramsConfig)) {
          console.warn(`Parameter "${key}" is not a valid query param.`)
          return
        }

        const paramName = paramsConfig[key].name
        if (value === undefined) {
          updatedParams.delete(paramName)
        } else {
          updatedParams.set(paramName, String(value))
        }
      })

      const updatedSearch = updatedParams.toString()
      if (location.search !== `?${updatedSearch}`) {
        if (options.replace) {
          navigate({ search: updatedSearch }, { replace: true })
        } else {
          navigate({ search: updatedSearch })
        }
      }
    },
    [location.search, navigate, paramsConfig] // Зависимости для мемоизации
  )

  return {
    params: validatedParams as ValidatedParams<T>,
    setQueryParams
  }
}
