import '@shared/lib/locale'
import ReactDOM from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import '../shared/assets/styles/variables.css'
import { App } from './app'
import { QueryProvider } from './providers/with-query'

import * as Sentry from '@sentry/react'
import { config } from '@shared/config'

const appMode = config.getWorkEnvironment()

if (appMode === 'production') {
  Sentry.init({
    dsn: 'https://e861e1abc9aa3470ff7ee1ea0c0e1e71@o4507069125689344.ingest.de.sentry.io/4508132740235344',
    normalizeDepth: 10,
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  })
}

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <QueryProvider>
    <App />
  </QueryProvider>
)
