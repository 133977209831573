import {
  type CountryListRequestDTO,
  countryRepository
} from '@sms_group/frontend-lib/entities/country'

const COUNTRY_QUERY_KEY = 'country'
const COUNTRY_WITH_OPERATOR_QUERY_KEY = 'country-with-operator'

export const countryListQuery = (dto: CountryListRequestDTO) => ({
  queryKey: [COUNTRY_QUERY_KEY, { ...dto }],
  queryFn: () => countryRepository.getList(dto),
  staleTime: 3600000,
  refetchOnWindowFocus: false,
  refetchOnMount: true
})

export const countryWithOperatorsListQuery = () => ({
  queryKey: [COUNTRY_WITH_OPERATOR_QUERY_KEY],
  queryFn: countryRepository.getListWithOperators,
  staleTime: 3600000,
  refetchOnWindowFocus: false,
  refetchOnMount: true
})
