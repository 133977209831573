import cn from 'classnames'
import '../shared/assets/styles/global-styles.css'
import '../shared/assets/styles/toast.css'

import { interceptors } from '@sms_group/frontend-lib/lib'

import { LoaderRoot } from '@components/loader-root'
import { config } from '@shared/config'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterProvider } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import styles from '../shared/assets/styles/index.module.css'
import { DataProvider } from './providers/with-data'
import { router } from './routing/routes'

export const App = () => {
  const { t } = useTranslation('server-errors')
  interceptors.useInterceptors(t, toast, config.getApiUrl())

  return (
    <Suspense fallback={<LoaderRoot />}>
      <DataProvider>
        <div className={styles.app}>
          <RouterProvider router={router} />
          <ToastContainer className={'toast'} hideProgressBar={true} />
          <div className={cn(styles.decorate, styles.circleRightOne)} />
          <div className={cn(styles.decorate, styles.circleRightTwo)} />
          <div className={cn(styles.decorate, styles.circleRightThree)} />
          <div className={cn(styles.decorate, styles.triangleRightOne)} />
          <div className={cn(styles.decorate, styles.triangleRightTwo)} />
          <div className={cn(styles.decorate, styles.circleLeftOne)} />
          <div className={cn(styles.decorate, styles.circleLeftTwo)} />
          <div className={cn(styles.decorate, styles.circleLeftThree)} />
          <div className={cn(styles.decorate, styles.circleLeftFour)} />
          <div className={cn(styles.decorate, styles.triangleLeftOne)} />
        </div>
      </DataProvider>
    </Suspense>
  )
}
