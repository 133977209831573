import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'

export const languageOptions = [
  {
    label: 'Russian',
    value: 'ru-RU'
  },
  {
    label: 'English',
    value: 'en-US'
  }
]

export const convertToDefaultLocale = (language: string) => {
  switch (language) {
    case 'ru-RU': {
      return 'ru'
    }
    case 'en-US': {
      return 'en'
    }
    default: {
      return language
    }
  }
}

const detectionOptions = {
  order: ['localStorage', 'sessionStorage', 'navigator'],
  // keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',

  // cache user language on

  caches: ['localStorage'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  convertDetectedLanguage: (lng: string | undefined) => {
    switch (lng) {
      case 'ru': {
        return 'ru-RU'
      }
      case 'en': {
        return 'en-US'
      }
      default: {
        return 'en-US'
      }
    }
  }
}

void i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-US',
    supportedLngs: ['ru-RU', 'en-US'],
    debug: false,
    interpolation: {
      escapeValue: false
    },
    keySeparator: '.',
    detection: detectionOptions,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  })

export default i18n
