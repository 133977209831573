import {
  type DepositStorageMethod,
  DepositMethodResponseDTO,
  depositRepository
} from '@sms_group/frontend-lib/entities/deposit'
import { dataLib } from '@sms_group/frontend-lib/lib'

const DEPOSIT_SERVICES_QUERY_KEY = 'DEPOSIT_SERVICES'
const DEPOSIT_SERVICE_QUERY_KEY = 'DEPOSIT_SERVICE'
const DEPOSIT_HISTORY_QUERY_KEY = 'DEPOSIT_HISTORY'

export const depositServiceListQuery = (
  { currency, paymentMethod }: { currency: string; paymentMethod: string },
  isAuth: boolean
) => ({
  queryKey: [DEPOSIT_SERVICES_QUERY_KEY, { currency, paymentMethod }],
  queryFn: async () =>
    await depositRepository.getServiceList({ currency, paymentMethod }),
  staleTime: 60000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  enabled: isAuth
})

export const depositMethodListQuery = (
  { currency, paymentMethod }: { currency: string; paymentMethod: string },
  favoriteMethods: DepositStorageMethod[],
  isAuth: boolean
) => ({
  queryKey: [DEPOSIT_SERVICE_QUERY_KEY, { currency, paymentMethod }],
  queryFn: async () =>
    await depositRepository.getMethodList({ currency, paymentMethod }),
  staleTime: 60000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  enabled: isAuth,
  select: (data: DepositMethodResponseDTO) => {
    return data?.sort((a, b) => {
      const aIsFavorite = favoriteMethods.some(
        (method) => method.currency === a.currency && method.method === a.method
      )
      const bIsFavorite = favoriteMethods.some(
        (method) => method.currency === b.currency && method.method === b.method
      )
      if (aIsFavorite && !bIsFavorite) {
        return -1
      }
      if (!aIsFavorite && bIsFavorite) {
        return 1
      }
      return a.method.localeCompare(b.method)
    })
  }
})

export const depositHistoryListQuery = (
  {
    startDt,
    endDt,
    sortingField,
    sortingType
  }: {
    startDt: string | undefined
    endDt: string | undefined
    sortingField: string | undefined
    sortingType: string | undefined
  },
  isAuth: boolean
) => {
  const count = 50
  const sortDescendance = () => {
    if (sortingType === 'asc' || sortingType === 'desc') return sortingType
    return undefined
  }
  const sort = sortDescendance()

  return {
    queryKey: [
      DEPOSIT_HISTORY_QUERY_KEY,
      {
        startDt,
        endDt,
        sortField: sortingField,
        sortType: sort,
        count
      }
    ],
    queryFn: ({ pageParam }: { pageParam: number }) =>
      depositRepository.getHistoryList(
        { startDt, endDt, sortField: sortingField, sortType: sort, count },
        pageParam
      ),
    initialPageParam: 0,
    staleTime: 60_000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isAuth,
    getNextPageParam: dataLib.getNextPageParam
  }
}
