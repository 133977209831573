import { useTranslation } from 'react-i18next'
import { OnChangeValue } from 'react-select'

import { Select } from '@components/select'
import { SelectOptionType } from '@sms_group/frontend-lib/config'
import { memo } from 'react'
import { languageOptions } from '../model/i18n'

interface LocaleProps extends React.ComponentPropsWithoutRef<'div'> {}

export const Locale = memo(({ className, ...rest }: LocaleProps) => {
  const { i18n } = useTranslation()

  const onSelect = async (
    selectedValue: OnChangeValue<SelectOptionType<string>, false>
  ) => {
    await i18n.changeLanguage(selectedValue?.value)
  }

  return (
    <Select
      className={className}
      options={languageOptions}
      value={{
        label: languageOptions.find((option) => option.value === i18n.language)
          ?.label,
        value: i18n.language
      }}
      menuPlacement="top"
      // @ts-ignore
      onChange={onSelect}
      {...rest}
    />
  )
})
