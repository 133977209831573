import { ApiCurrentPage, ApiCurrentPageError } from '@pages/api-current-page'
import { ApiCurrentPageLoader } from '@pages/api-current-page/api-current-page.loader'
import { ApiOldPage, ApiOldPageError } from '@pages/api-old-page'
import { ApiOldPageLoader } from '@pages/api-old-page/api-old-page.loader'
import { ApiPage, ApiPageError } from '@pages/api-page'
import { ApiV2Page, ApiV2PageError } from '@pages/api-v2-page'
import { ApiV2PageLoader } from '@pages/api-v2-page/api-v2-page.loader'
import { BuyNumbersPage, BuyNumbersPageError } from '@pages/buy-numbers-page'
import { BuyNumbersPageLoader } from '@pages/buy-numbers-page/buy-numbers-page.loader'
import { ContactsPage, ContactsPageError } from '@pages/contacts-page'
import { ContactsPageLoader } from '@pages/contacts-page/contacts-page.loader'
import {
  DepositFailurePage,
  DepositFailurePageError
} from '@pages/deposit-failure-page'
import { DepositFailurePageLoader } from '@pages/deposit-failure-page/deposit-failure-page.loader'
import { DepositPage, DepositPageError } from '@pages/deposit-page'
import { DepositPageLoader } from '@pages/deposit-page/deposit-page.loader'
import {
  DepositSuccessPage,
  DepositSuccessPageError
} from '@pages/deposit-success-page'
import { DepositSuccessPageLoader } from '@pages/deposit-success-page/deposit-success-page.loader'
import { DevelopersPage, DevelopersPageError } from '@pages/developers-page'
import { DevelopersPageLoader } from '@pages/developers-page/developers-page.loader'
import { ForbiddenPage, ForbiddenPageError } from '@pages/forbidden-page'
import { ForbiddenPageLoader } from '@pages/forbidden-page/forbidden-page.loader'
import {
  ForgetPasswordPage,
  ForgetPasswordPageError
} from '@pages/forget-password-page'
import { ForgetPasswordPageLoader } from '@pages/forget-password-page/forget-password-page.loader'
import {
  HistoryNumberPage,
  HistoryNumbersPageError
} from '@pages/history-numbers-page'
import { HistoryNumbersPageLoader } from '@pages/history-numbers-page/history-numbers-page.loader'
import {
  HistoryTopupPage,
  HistoryTopupPageError
} from '@pages/history-topup-page'
import { HistoryTopupPageLoader } from '@pages/history-topup-page/history-topup-page.loader'
import {
  HistoryWithdrawPage,
  HistoryWithdrawPageError
} from '@pages/history-withdraw-page'
import { HistoryWithdrawPageLoader } from '@pages/history-withdraw-page/history-withdraw-page.loader'
import { LayoutPage, LayoutPageError } from '@pages/layout-page'
import { MainPage, MainPageError } from '@pages/main-page'
import { MenuPage, MenuPageError } from '@pages/menu-page'
import { MenuPageLoader } from '@pages/menu-page/menu-page.loader'
import { NotFoundPage, NotFoundPageError } from '@pages/not-found-page'
import { NotFoundPageLoader } from '@pages/not-found-page/not-found-page.loader'
import { OfficePage, OfficePageError } from '@pages/office-page'
import { ProfilePage, ProfilePageError } from '@pages/profile-page'
import { ProfilePageLoader } from '@pages/profile-page/profile-page.loader'
import {
  ProfileSettingsPage,
  ProfileSettingsPageError
} from '@pages/profile-settings-page'
import { ProfileSettingsPageLoader } from '@pages/profile-settings-page/profile-settings-page.loader'
import { SignInPage, SigninPageError } from '@pages/signin-page'
import { SigninPageLoader } from '@pages/signin-page/signin-page.loader'
import { SignUpPage, SignupPageError } from '@pages/signup-page'
import { SignUpPageLoader } from '@pages/signup-page/signup-page.loader'
import { TermsPage, TermsPageError } from '@pages/terms-page'
import { TermsPageLoader } from '@pages/terms-page/terms-page.loader'
import {
  VerificationCodePage,
  VerificationCodePageError
} from '@pages/verification-code-page'
import { VerificationCodePageLoader } from '@pages/verification-code-page/verification-code-page.loader'
import { PAGES } from '@shared/config'
import { RequireAuth } from '@shared/session/ui/require-auth'
import { Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

export const router = createBrowserRouter([
  {
    element: <LayoutPage />,
    errorElement: <LayoutPageError />,
    children: [
      {
        path: PAGES.ROOT_PAGE,
        errorElement: <MainPageError />,
        element: <MainPage />
      },
      {
        path: PAGES.CONTACTS_PAGE,
        errorElement: <ContactsPageError />,
        element: (
          <Suspense fallback={<ContactsPageLoader />}>
            <ContactsPage />
          </Suspense>
        )
      },
      {
        path: PAGES.MENU_PAGE,
        errorElement: <MenuPageError />,
        element: (
          <Suspense fallback={<MenuPageLoader />}>
            <MenuPage />
          </Suspense>
        )
      },
      {
        path: PAGES.BUY_PAGE,
        errorElement: <BuyNumbersPageError />,
        element: (
          <Suspense fallback={<BuyNumbersPageLoader />}>
            <BuyNumbersPage />
          </Suspense>
        )
      },
      {
        path: PAGES.TERMS_PAGE,
        errorElement: <TermsPageError />,
        element: (
          <Suspense fallback={<TermsPageLoader />}>
            <TermsPage />
          </Suspense>
        )
      },
      {
        path: PAGES.VERIFICATION_MAIL_PAGE,
        errorElement: <VerificationCodePageError />,
        element: (
          <Suspense fallback={<VerificationCodePageLoader />}>
            <VerificationCodePage />
          </Suspense>
        )
      },
      {
        path: PAGES.DEVELOPERS_PAGE,
        errorElement: <DevelopersPageError />,
        element: (
          <Suspense fallback={<DevelopersPageLoader />}>
            <DevelopersPage />
          </Suspense>
        )
      },
      {
        path: PAGES.AUTH_PAGE,
        errorElement: <SigninPageError />,
        element: (
          <Suspense fallback={<SigninPageLoader />}>
            <SignInPage />
          </Suspense>
        )
      },
      {
        path: PAGES.REGISTER_PAGE,
        errorElement: <SignupPageError />,
        element: (
          <Suspense fallback={<SignUpPageLoader />}>
            <SignUpPage />
          </Suspense>
        )
      },
      {
        path: PAGES.FORGET_PASSWORD_PAGE,
        errorElement: <ForgetPasswordPageError />,
        element: (
          <Suspense fallback={<ForgetPasswordPageLoader />}>
            <ForgetPasswordPage />
          </Suspense>
        )
      },
      {
        path: PAGES.FORBIDDEN_PAGE,
        errorElement: <ForbiddenPageError />,
        element: (
          <Suspense fallback={<ForbiddenPageLoader />}>
            <ForbiddenPage />
          </Suspense>
        )
      },
      {
        path: PAGES.NOT_FOUND_PAGE,
        errorElement: <NotFoundPageError />,
        element: (
          <Suspense fallback={<NotFoundPageLoader />}>
            <NotFoundPage />
          </Suspense>
        )
      },
      {
        path: '*',
        element: <Navigate to={PAGES.NOT_FOUND_PAGE} replace />
      },

      {
        path: PAGES.API_PAGE.INDEX,
        element: <ApiPage />,
        errorElement: <ApiPageError />,
        children: [
          {
            path: PAGES.API_PAGE.CURRENT,
            errorElement: <ApiCurrentPageError />,
            element: (
              <Suspense fallback={<ApiCurrentPageLoader />}>
                <ApiCurrentPage />
              </Suspense>
            )
          },
          {
            path: PAGES.API_PAGE.OLD,
            errorElement: <ApiOldPageError />,
            element: (
              <Suspense fallback={<ApiOldPageLoader />}>
                <ApiOldPage />
              </Suspense>
            )
          },
          {
            path: PAGES.API_PAGE.V2,
            errorElement: <ApiV2PageError />,
            element: (
              <Suspense fallback={<ApiV2PageLoader />}>
                <ApiV2Page />
              </Suspense>
            )
          },
          {
            index: true,
            element: <Navigate to={PAGES.API_PAGE.V2} />
          }
        ]
      },
      {
        element: <RequireAuth />,
        children: [
          {
            path: PAGES.DEPOSIT_PAGE,
            errorElement: <DepositPageError />,
            element: (
              <Suspense fallback={<DepositPageLoader />}>
                <DepositPage />
              </Suspense>
            )
          },
          {
            path: PAGES.PK_PAGE.INDEX,
            errorElement: <OfficePageError />,
            element: <OfficePage />,
            children: [
              {
                path: PAGES.PK_PAGE.PROFILE_PAGE,
                errorElement: <ProfilePageError />,
                element: (
                  <Suspense fallback={<ProfilePageLoader />}>
                    <ProfilePage />
                  </Suspense>
                )
              },
              {
                path: PAGES.PK_PAGE.TOPUP_HISTORY_PAGE,
                errorElement: <HistoryTopupPageError />,
                element: (
                  <Suspense fallback={<HistoryTopupPageLoader />}>
                    <HistoryTopupPage />
                  </Suspense>
                )
              },
              {
                path: PAGES.PK_PAGE.WITHDRAW_HISTORY_PAGE,
                errorElement: <HistoryWithdrawPageError />,
                element: (
                  <Suspense fallback={<HistoryWithdrawPageLoader />}>
                    <HistoryWithdrawPage />
                  </Suspense>
                )
              },
              {
                path: PAGES.PK_PAGE.SETTINGS,
                errorElement: <ProfileSettingsPageError />,
                element: (
                  <Suspense fallback={<ProfileSettingsPageLoader />}>
                    <ProfileSettingsPage />
                  </Suspense>
                )
              },
              {
                index: true,
                element: <Navigate to={PAGES.PK_PAGE.PROFILE_PAGE} />
              }
            ]
          },
          {
            path: PAGES.NUMBER_HISTORY_PAGE,
            errorElement: <HistoryNumbersPageError />,
            element: (
              <Suspense fallback={<HistoryNumbersPageLoader />}>
                <HistoryNumberPage />
              </Suspense>
            )
          },
          {
            path: PAGES.SUCCESS_DEPOSIT_PAGE,
            errorElement: <DepositSuccessPageError />,
            element: (
              <Suspense fallback={<DepositSuccessPageLoader />}>
                <DepositSuccessPage />
              </Suspense>
            )
          },
          {
            path: PAGES.FAILED_DEPOSIT_PAGE,
            errorElement: <DepositFailurePageError />,
            element: (
              <Suspense fallback={<DepositFailurePageLoader />}>
                <DepositFailurePage />
              </Suspense>
            )
          }
        ]
      }
    ]
  }
])
