import { config } from '@shared/config'
import { URL_PARAMS, WORK_MODE } from '@shared/config/constants'
import { useValidatedQueryParams } from '@shared/lib/url'
import { useEffect } from 'react'
import { z } from 'zod'
import { devtools } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export type LayoutStore = {
  hasTopPanel: boolean
  hasBottomPanel: boolean
  hasRightPannel: boolean
  setTopPanelExists: (value: boolean) => void
  setBottomPanelExists: (value: boolean) => void
  setRightPanelExists: (value: boolean) => void
  reset: () => void
}

export const layoutStore = createWithEqualityFn<LayoutStore>()(
  devtools(
    (set) => ({
      hasTopPanel: true,
      hasBottomPanel: false,
      hasRightPannel: false,
      setTopPanelExists: (value: boolean) =>
        set(
          (state) => ({ ...state, hasTopPanel: value }),
          false,
          'setTopPanelExists'
        ),
      setBottomPanelExists: (value: boolean) =>
        set(
          (state) => ({ ...state, hasBottomPanel: value }),
          false,
          'setBottomPanelExists'
        ),
      setRightPanelExists: (value: boolean) =>
        set(
          (state) => ({ ...state, hasRightPannel: value }),
          false,
          'setRightPanelExists'
        ),
      reset: () =>
        set(
          (state) => ({
            ...state,
            hasBottomPanel: false,
            hasRightPannel: false
          }),
          false,
          'reset'
        )
    }),
    {
      name: 'layout',
      enabled: config.isDevAppMode()
    }
  ),
  shallow
)

export const useLayout = () => {
  const { params } = useValidatedQueryParams({
    mode: {
      type: z.string().optional(),
      name: URL_PARAMS.MODE
    },
    country: {
      type: z.string().optional(),
      name: URL_PARAMS.COUNTRY
    },
    service: {
      type: z.string().optional(),
      name: URL_PARAMS.SERVICE
    }
  })
  const setBottomPanelExists = layoutStore(
    (state) => state.setBottomPanelExists
  )
  const { bottom, right } = layoutStore((state) => ({
    bottom: state.hasBottomPanel,
    right: state.hasRightPannel
  }))

  useEffect(() => {
    if (params.mode === WORK_MODE.COUNTRY) {
      if (params.country === undefined && bottom !== false) {
        setBottomPanelExists(false)
      } else if (params.country !== undefined && bottom !== true) {
        setBottomPanelExists(true)
      }
    } else {
      if (params.service === undefined && bottom !== false) {
        setBottomPanelExists(false)
      } else if (params.service !== undefined && bottom !== true) {
        setBottomPanelExists(true)
      }
    }
  }, [params.country, params.service, params.mode])

  const isServicesVisible =
    params.mode === WORK_MODE.SERVICE ||
    (params.mode === WORK_MODE.COUNTRY && params.country !== undefined)

  const isCountriesVisible =
    params.mode === WORK_MODE.COUNTRY ||
    (params.mode === WORK_MODE.SERVICE && params.service !== undefined)

  return {
    hasOnlyTopPanel: right === false && bottom === false,
    hasDoublePanels: right === true && bottom === false,
    hasRightPannel: right === true,
    hasAllPanels: bottom === true && right === true,
    isServicesFirst: params.mode === WORK_MODE.SERVICE,
    isCountriesVisible,
    isServicesVisible
  }
}
